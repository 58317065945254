
.main {
    width: 100%; height: 100%;
    flex-direction: column;
    justify-items: flex-start;
}

.title {
    align-items: center;
    height: 15%;
    padding-left: 24px;
}

.infoBlock {
    margin-top: 1%;
    align-items: center;
    width: 100%; height: 30px;
    padding-left: 24px;
    padding-right: 24px;
}
.infoBlock p {
    font-weight: 600;
    color: #b4b4b4;
    font-size: 14px;
}
.infoBlock .courseName {width: 60%}
.infoBlock .progress {
    width: 25%;
    margin-left: 7%;
}

.faculty {
    font-weight: 600;
    font-size: 24px;
    color: var(--black-color);
}

.semester {
    margin-left: 70px;
    font-weight: 600;
    font-size: 22px;
    color: var(--black-color);
}
.semester sup {
    margin-right: 10px;
    font-weight: 400;
    margin-top: -5px;
    font-size: 16px;
}

.coursesList {
    width: 100%; height: 90%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: scroll;
}

.course {
    user-select: none;
    cursor: pointer;
    align-items: center;
}
.course:hover {
    background-color: #e5efff;
}

.courseTitle {
    flex-direction: column;
    width: 60%;
    justify-items: center;
    margin-left: 2%;
    align-items: start;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--black-color);
}

.count {
    color: var(--black-color);
}
.loading {
    width: 25%; height: 8px;
    margin-left: 5%;
    margin-right: 2%;
    border-radius: 20px;
    background-color: #e8e8e8;
    flex-direction: column;
    align-items: start;
}

.loadingBar {
    height: 8px;
    background-color: #199a5c;
    border-radius: 20px;
    animation-name: loading;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes loading {
    from {width: 0;}
}

.createCourseBTN {
    position: absolute;
}

