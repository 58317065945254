
.main {
    width: 100%; height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loginBlock {
    width: 550px; height: 525px;
    background-color: var(--primary-color);
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
}

.logo {
    font-size: 48px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
}
.logo b { color: var(--red-color); }

form { flex-direction: column; }

.input{
    flex-direction: column;
    margin-top: 20px;
}
.input label{
    margin-bottom: 2px;
    color: white;
}
.input input{
    width: 300px;
    height: 35px;
    border: none;
    border-radius: 2px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
}
.input select{
    width: 300px;
    height: 35px;
    border: none;
    border-radius: 2px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 15px;
    outline: none;
}
.input input:focus{ outline: none; }

.submit {
    justify-content: center;
}

.submitButton {
    margin-top: 20px;
    border: none;
    background-color: var(--red-color);
    height: 35px; width: 100px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    color: var(--background-color);
    border-radius: 3px;
    font-weight: 500;
}

.signUp {margin-top: 10px;}
.signUpButton {
    margin-left: 10px;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;
    user-select: none;
}

.validationError {
    position: fixed;
    top: 30px;
    width: 550px; height: 80px;
    background-color: white;
    animation-name: appear;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}
.validationError .exclamation {
    border: 2px solid red;
    color: red;
    width: 24px; height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 700;
    margin-right: 5px;
}
@keyframes appear {
    from {top: -100px;}
}