
.main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; height: 100%;
}

.box {
    width: 95%; height: 92%;
    background-color: white;
    border-radius: 15px;
}

