@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");


:root {
  --main-font: 'Inter', sans-serif;
  --primary-color: #1424b1;
  --secondary-color: #2336df;
  --black-color: #2e2e2e;
  --red-color: #d33c3c;
  --background-color: #f2f2ff;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body, html{
  margin: 0; padding: 0;
  width: 100%; height: 100%;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app * {
  display: flex;
  transition: .3s ease;
  font-family: var(--main-font);
}

.loader {
  border: 8px solid #F2F2FF;
  border-top: 8px solid #1524B1;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  margin-top: 20%; margin-left: 45%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:hover {
  background: #d0d0d0;
}




