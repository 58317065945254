
.main {
    width: 100%;
    flex-direction: column;
}

.profileTitle {
    font-weight: 600;
    font-size: 24px;
    color: var(--black-color);
    width: 100%; height: 15%;
    align-items: center;
    padding-left: 24px;
}

.mainUserSettings {
    width: 100%; height: 95%;
    margin-top: 5%;
    overflow: hidden;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
}

label.avatar_upload input[type="file"]{
    display: none;
}
label.avatar_upload {
    border: 1px solid #bbc2ff;
    width: 120px; height: 120px;
    border-radius: 50%;
    background-color: #f8f8fe;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
}
.defaultProfileIcon {
    position: absolute;
    content: "";
    width: 72px; height: 78px;
    background-repeat: no-repeat;
    background-size: 72px;
    background-image: url("../../assets/icons/profile_avatar.svg");
    user-select: none;
}
.avatarInput{
    width: 120px;
    flex-direction: column;
    align-items: center;
}
.avatarInput div{
    color: #38505A;
    font-size: 14px;
}
.default_avatar{
    justify-content: center;
    align-items: center;
}
.default_avatar .inputAvatarImage{
    width: 120px; height: 120px;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
}

.section {
    width: 500px;
    margin-top: 25px;
    justify-content: space-between;
}
.section div {
    flex-direction: column;
}
.section div input, .section div select {
    height: 35px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f8f8fe;
    outline: none;
    border: 1px solid #bbc2ff;
    font-size: 16px;
    font-weight: 300;
    color: var(--black-color);
    outline: none;
}
.section .inputShort {width: 240px;}
.section .inputLong {width: 500px;}
.section label {
    color: #b4b4b4;
    font-size: 12px;
    margin-bottom: 3px;
}

.mainUserSettings button {
    padding: 5px 25px;
    color: white;
    background-color: #2e42f8;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.inputLong {
    position: relative;
}

.section div p {
    position: absolute;
    margin-top: 55px;
    color: red;
    font-size: 12px;
}

.addItems {
    width: 500px;
    justify-content: space-between;
    margin-top: 25px;
}
.addItems div {
    background-color: #418fff;
    width: 45%; height: 60px;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
}

.subject {
    margin-top: 30px;
    border: 1px solid #bbc2ff;
    background-color: #f8f8fe;
    border-radius: 4px;
    align-items: center;
    width: 500px;
    flex-direction: column;
}
.subject .subjectTitle {
    margin-top: 15px;
    margin-bottom: 30px;
    color: var(--black-color);
    position: relative;
}
.subjectTitle input {
    width: 400px;
    padding-left: 8px;
    padding-right: 8px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: transparent;
    border-bottom: 1px solid #cecece;
}
.subjectTitle input[type="text"] {
    font-family: var(--main-font);
}
.deleteSubject::after{
    content: '\d7';
    font-size: 28px;
    position: absolute;
    cursor: pointer;
    margin: -12px 0 0 16px;
    color: #575757;
}
.subject .addFilesButton {
    margin-top: 25px;
    background-color: #418fff;
    color: white;
    padding: 4px 25px;
    user-select: none;
    border-radius: 4px;
    cursor: pointer;
}
.uploadTextPrompt {
    margin-top: -15px;
    font-size: 12px;
    color: #9a9a9a;
    margin-bottom: 15px;
}
input[type="file"] {
    display: none;
    visibility: hidden;
}

.files {
    flex-direction: column;
}
.document{
    border: 1px solid #96A3A8;
    width: 400px; height: 100px;
    margin-bottom: 10px;
    border-radius: 8px;
    flex-direction: column;
}
.topBlock{
    margin-top: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.textBlock, .imgTextBlock{
    margin: 10px;
    width: calc(77%);
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
div.documentTitle{
    display: inline-block;
    position: relative;
    width: 100%; height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px;
    color: #38505A;
    font-size: 14px;
}
div.documentSize{
    font-size: 12px;
    color: #677982;
    margin-left: 20px;
    margin-top: 2px;
}
.documentImage{
    width: 40px; height: 40px;
    border-radius: 50%;
    background-color: #E1EDF3;
    justify-content: center;
    align-items: center;
}
.documentImage:after{
    content: '';
    background-image: url("../../assets/icons/file.svg");
    width: 14px; height: 17px;
    background-repeat: no-repeat;
    background-size: 14px;
}
.documentDelete{
    background-image: url("../../assets/icons/delete-bin.svg");
    width: 20px; height: 22px;
    background-repeat: no-repeat;
    background-size: 20px;
    cursor: pointer;
}
.bottomBlock{
    width: 100%;
    margin-top: 20px;
    justify-content: center;
}
div.loadingBar{
    width: 70%;
    position: relative;
}
div.loadingBarBlue{
    position: absolute;
    margin-right: 10%;
    width: 100%;
    background-color: #78acfa;
}
.animateLoading{
    animation-name: loadingDocument;
    animation-duration: 1.25s;
    transition: .3s ease;
}
@keyframes loadingDocument {
    from {width: 0;}
    to {width: 100%;}
}
div.loadingBarBlue, div.loadingBarDisabled{
    height: 6px;
    border-radius: 5px;
    margin-top: 5px;
}
div.loadingBarDisabled{
    background-color: #EAF0F4;
}
div.loadingPercent{
    width: 10%;
    font-size: 14px;
    margin-left: 10px;
    text-align: center;
    color: #38505A;
}

.addQuestionButton {
    margin-bottom: 20px;
    background-color: #418fff;
    color: white;
    padding: 4px 25px;
    user-select: none;
    border-radius: 4px;
    cursor: pointer;
}

.questionsList {
    flex-direction: column;
}
.quizScore {
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--black-color);
}
.question {
    margin-bottom: 40px;
    flex-direction: column;
}
.questionTitle textarea {
    width: 250px; height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: transparent;
    border-bottom: 1px solid #cecece;
    font-family: var(--main-font);
    resize: none;
    margin-right: 20px;
}
.questionHeader select {
    width: 120px;
    outline: none;
    border-radius: 3px;
    border: 1px solid #cecece;
    font-size: 13px;
    padding: 3px;
}
.responseList {
    margin-top: 10px;
    flex-direction: column;
}

.responseList div {
    margin-top: 10px;
}
.multiResponseType input, .oneResponseType input {
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #cecece;
}
.multiResponseType, .oneResponseType{
    position: relative;
}
.responseTitle {
    margin-left: 10px;
    width: 340px;
}
.responseIsCorrect {
    width: 16px; height: 16px;
    cursor: pointer;
}
.deleteResponse::after {
    content: '\d7';
    font-size: 22px;
    position: absolute;
    cursor: pointer;
    margin-top: -16px;
    color: #575757;
}

.deleteQuestion {
    cursor: pointer;
    font-size: 14px;
    color: #575757;
    align-items: center;
    user-select: none;
}
.responseActions {
    margin-top: 10px;
    justify-content: space-between;
}
.deleteQuestion i{
    background-image: url("../../assets/icons/delete-bin.svg");
    width: 18px; height: 20px;
    background-repeat: no-repeat;
    background-size: 18px;
}
.addResponse {
    font-size: 14px;
    color: #418fff;
    user-select: none;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
}
.addResponse::before{
    content: '\002b';
    font-size: 27px;
    color: #418fff;
    position: absolute;
    margin: -10px 0 0 -18px;
}
