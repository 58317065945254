
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.logo {
    font-size: calc(100vw/1920 * 48);
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: calc(100vh/1080 * 50);
}
@media only screen and (max-height: 700px) {
    .logo {
        margin-bottom: calc(100vw/1080 * 10);
    }
}
.logo b{
    color: var(--red-color);
}

.navigationButtons {
    flex-direction: column;
    width: 100%;
    margin: 15% 0 15% 0;
    align-items: center;
}
.navigationButtons a {
    cursor: pointer;
    align-items: center;
    height: 60px; width: 100%;
}
.navigationButtons a:hover, .logout:hover {
    background-color: var(--secondary-color);
}
.navigationButtons a .section {
    width: 170px;
    align-items: center;
    margin-left: 25%;
}
.navigationTitle {
    font-family: var(--main-font);
    font-size: calc(100vw/1920 * 22);
    color: white;
    margin-left: 10px;
    user-select: none;
}

.dashboardBTN i, .filesBTN i, .coursesBTN i,
.gradesBTN i, .notificationsBTN i, .settingsBTN i,
.profileBTN i, .logout i {
    content: "";
    width: 28px; height: 28px;
    background-repeat: no-repeat;
    user-select: none;
}
.dashboardBTN i {
    background-image: url("../../assets/icons/dashboard.svg");
    background-size: 27px;
}
.filesBTN i {
    background-size: 24px;
    background-image: url("../../assets/icons/files.svg");
}
.coursesBTN i {
    background-size: 27px;
    background-image: url("../../assets/icons/courses.svg");
}
.gradesBTN i {
    background-size: 28px;
    background-image: url("../../assets/icons/grades.svg");
}
.notificationsBTN i {
    background-size: 25px;
    background-image: url("../../assets/icons/notifications.svg");
}
.settingsBTN i {
    background-size: 26px;
    background-image: url("../../assets/icons/settings.svg");
}
.profileBTN i {
    background-size: 27px;
    background-image: url("../../assets/icons/profile.svg");
}
.logout  i {
    background-size: 27px;
    background-image: url("../../assets/icons/logout.svg");
}

.logout {
    cursor: pointer;
    height: 60px; width: 100%;
    align-items: center;
    justify-content: center;
}

.logoutTitle {
    font-size: calc(100vw/1920 * 24);
    color: white;
    margin-left: 10px;
    font-weight: 500;
    user-select: none;
    margin-right: 5%;
}
