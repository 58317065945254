
.main {
    justify-content: center;
}

.darkBG {
    position: absolute;
    background-color: black;
    z-index: 10;
    opacity: 0.6;
    width: 100%; height: 100%;
}

.box {
    position: absolute;
    z-index: 11;
    margin-top: 8%;
    width: 70%; height: 70%;
    background-color: white;
    border-radius: 10px;
}