
.main {
    width: 100%; height: 100%;
    display: flex;
}

.navigation {
    width: 20%; height: 100vh;
    background-color: var(--primary-color);
}

.mainAppBlock {
    display: flex;
    flex-direction: column;
    width: 80%; height: 100vh;
}

.header {
    background-color: white;
    height: 12%; width: 100%;
}

.content {
    width: 100%; height: 88%;
    background-color: var(--background-color);
}