
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; height: 100%;
}

.navigation {
    width: 70%;
    font-weight: 600;
    font-size: 24px;
    color: var(--black-color);
    margin-left: 28px;
}

.date {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%; height: 100%;
    cursor: pointer;
}

.calendarIcon {
    content: "";
    background-image: url("../../assets/icons/calendar.svg");
    width: calc(100vw/1920 * 30);
    height: calc(100vw/1920 * 30);
    background-size: calc(100vw/1920 * 26);
    background-repeat: no-repeat;
    user-select: none;
}

.calendarTitle {
    color: var(--black-color);
    font-size: calc(100vw/1920 * 22);
    font-weight: 500;
    margin-left: 5px;
    margin-right: 10%;
    user-select: none;
}

.createCourseBTN {
    cursor: pointer;
    margin-left: 30px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-color);
    color: white;
    padding: 2px 20px;
    border-radius: 5px;
    user-select: none;
}
