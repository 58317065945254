.main {
    width: 100%; height: 100%;
    flex-direction: column;
}

.profileTitle {
    font-weight: 600;
    font-size: 24px;
    color: var(--black-color);
    width: 100%; height: 15%;
    align-items: center;
    padding-left: 24px;
}

.mainQuizList {
    width: 100%; height: 90%;
    overflow: hidden;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
}

.quiz {
    width: 95%;
    flex-direction: column;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 25px;
    background-color: var(--background-color);
}
.quizTitle {
    font-size: 18px;
    font-weight: 500;
    color: var(--black-color);
    margin-bottom: 20px;
}

.oneResponseList, .multiResponseList {
    margin-left: 10px;
    flex-direction: column;
}
.oneResponseList p, .multiResponseList p {
    margin: 0 0 0 15px;
    color: var(--black-color);
}
.oneResponseList input[type="radio"] {
    background-color: #fff;
    margin: 0;
    font: inherit;
    cursor: pointer;
    color: currentColor;
    width: 1.15em; height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
}
.multiResponseList input[type="checkbox"] {
    background-color: #fff;
    margin: 0;
    font: inherit;
    cursor: pointer;
    color: currentColor;
    width: 1.1em; height: 1.1em;
    border: 0.1em solid currentColor;
}
.oneResponse, .multiResponse {margin-bottom: 10px}

.textResponse textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    border-bottom: 1px solid #bebebe;
    border-top: none; border-left: none; border-right: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
}
.textResponse textarea:focus {
    border-color: var(--secondary-color);
    border-width: 2px;
}

.submitButton {
    width: 95%;
    float: left;
}
.submitButton button {
    background-color: #2e42f8;
    color: white;
    cursor: pointer;
    padding: 5px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
}
