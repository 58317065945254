
.main {
    width: 100%; height: 100%;
    flex-direction: column;
}

.profileTitle {
    font-weight: 600;
    font-size: 24px;
    color: var(--black-color);
    width: 100%; height: 15%;
    align-items: center;
    padding-left: 24px;
}

.mainUserSettings {
    width: 100%; height: 95%;
    margin-top: 5%;
    overflow: hidden;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
}

label.avatar_upload input[type="file"]{
    display: none;
}
label.avatar_upload {
    border: 1px solid #bbc2ff;
    width: 120px; height: 120px;
    border-radius: 50%;
    background-color: #f8f8fe;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: relative;
}
.defaultProfileIcon {
    position: absolute;
    content: "";
    width: 72px; height: 78px;
    background-repeat: no-repeat;
    background-size: 72px;
    background-image: url("../../assets/icons/profile_avatar.svg");
    user-select: none;
}
.avatarInput{
    width: 120px;
    flex-direction: column;
    align-items: center;
}
.avatarInput div{
    color: #38505A;
    font-size: 14px;
}
.default_avatar{
    justify-content: center;
    align-items: center;
}
.default_avatar .inputAvatarImage{
    width: 120px; height: 120px;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
}

.section {
    width: 470px;
    margin-top: 25px;
    justify-content: space-between;
}
.section div {
    flex-direction: column;
}
.section div input {
    height: 35px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f8f8fe;
    outline: none;
    border: 1px solid #bbc2ff;
    font-size: 16px;
    font-weight: 300;
    color: var(--black-color);
}
.section .inputShort {width: 220px;}
.section .inputLong {width: 470px;}
.section label {
    color: #b4b4b4;
    font-size: 12px;
    margin-bottom: 3px;
}

.mainUserSettings button {
    padding: 5px 25px;
    color: white;
    background-color: #2e42f8;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.passwordVisible {
    position: absolute;
    cursor: pointer;
    width: 40px; height: 35px;
    background-color: #f8f8fe;
    border-bottom: 1px solid #bbc2ff;
    border-top: 1px solid #bbc2ff;
    border-right: 1px solid #bbc2ff;
    content: "";
    background-repeat: no-repeat;
    user-select: none;
    background-image: url("../../assets/icons/eye-slash.svg");
    background-size: 20px;
    background-position: center;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 18px 0 0 430px;
}
.visible {
    background-image: url("../../assets/icons/eye-slash.svg");
}
.invisible {
    background-image: url("../../assets/icons/eye.svg");
}
.inputLong {
    position: relative;
}

.section div p {
    position: absolute;
    margin-top: 55px;
    color: red;
    font-size: 12px;
}
